import { useEffect, useState } from "react";
import { Col, Row, Card, Form, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserModel from '../../models/user.model';
import UserService from "../../services/User.service";
import { toast, ToastContainer } from "react-toastify";


const UserDetails = () => {
    const { t } = useTranslation();
    const userService = new UserService();
    const [userData, setUserData] = useState<UserModel>({});
    const [validated, setValidated] = useState(false);
    const [inAction, setInAction] = useState(false);

    document.body.className='';

    useEffect(() => {
        const user = userService.getUser() as UserModel;
        setUserData(user);
    }, []);

    const onSaveProfile = (event: any) => {
        setInAction(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        }
        else {
            userService.modifyUser(userData).then((response: any) => {
                if ('status' in response) {
                    if (response.status === 200) {
                        toast.success(t('profileUpdated'));
                    }
                    else {
                        toast.error(t('profileUpdateError'));
                    }
                }
                setInAction(false);
            });
        }
        event.preventDefault();
        event.stopPropagation();
    };

    const handleFormChange = (event: any) => {
        const { name, value } = event.target;
        setUserData({...userData, [name]: value });
    };


    return(
        <>
            <ToastContainer />
            <Form noValidate validated={validated} onSubmit={onSaveProfile} className="page-profile">
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <Card className="profile">
                            <Card.Body>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <h1>{t('focimProfilAdatok')}</h1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6} md={12} sm={12} xs={12} className="align-top">
                                        <Form.Group className="mb-3" controlId="firstname">
                                            <Form.Label>{t('firstname')}</Form.Label>
                                            <Form.Control required placeholder={t('firstnamePlaceholder')} className="nsu-form-control" type="text" name="firstname" value={userData.firstname} onChange={handleFormChange} />
                                            <Form.Control.Feedback type="invalid">
                                                {t('validText')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={12} sm={12} xs={12} className="align-top">
                                        <Form.Group className="mb-3" controlId="lastname">
                                            <Form.Label>{t('lastname')}</Form.Label>
                                            <Form.Control required placeholder={t('lastnamePlaceholder')} className="nsu-form-control" type="text" name="lastname" value={userData.lastname} onChange={handleFormChange} />
                                            <Form.Control.Feedback type="invalid">
                                                {t('validText')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12} className="align-top">
                                        <Form.Group className="mb-3" controlId="phone">
                                            <Form.Label>{t('phone')}</Form.Label>
                                            <Form.Control placeholder={t('phonePlaceholder')} className="nsu-form-control" type="text" name="phone" value={userData.phone} onChange={handleFormChange} />
                                            <Form.Control.Feedback type="invalid">
                                                {t('validPhone')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12} className="align-top">
                                        <Form.Group className="mb-3" controlId="company">
                                            <Form.Label>{t('company')}</Form.Label>
                                            <Form.Control placeholder={t('companyPlaceholder')} className="nsu-form-control" type="text" name="company" value={userData.company} onChange={handleFormChange} />
                                            <Form.Control.Feedback type="invalid">
                                                {t('validCompany')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12} className="align-top">
                                        <Form.Group className="mb-3" controlId="tax">
                                            <Form.Label>{t('tax')}</Form.Label>
                                            <Form.Control placeholder={t('taxPlaceholder')} className="nsu-form-control" type="text" name="tax" value={userData.tax} onChange={handleFormChange} />
                                            <Form.Control.Feedback type="invalid">
                                                {t('validTax')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12} className="align-top">
                                        <Form.Group className="mb-3" controlId="address">
                                            <Form.Label>{t('address')}</Form.Label>
                                            <Form.Control placeholder={t('addressPlaceholder')} className="nsu-form-control" type="text" name="address" value={userData.address} onChange={handleFormChange} />
                                            <Form.Control.Feedback type="invalid">
                                                {t('validAddress')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-align-right">
                                        <Button type="button" className="btn btn-none-nsu color-danger">
                                            {t('cancelChangesBtn')}
                                        </Button>
                                        <Button type="submit" className="btn btn-middle-nsu color-danger" disabled={inAction}>
                                            { inAction && 
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true" /> 
                                            } {t('saveChangesBtn')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default UserDetails;