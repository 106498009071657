import { Container, Navbar, Figure, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserService from "../../services/User.service";
import UserModel from "../../models/user.model";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import eventEmitter from "../../services/EventProvider.service";

const Menu = () => {

    const { t } = useTranslation();
    const [userFullname, setUserFullname] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const userService = new UserService();

    const updateHeader = ((modifiedUserData: UserModel) => {
        console.log(modifiedUserData);
        const name = modifiedUserData && 'firstname' in modifiedUserData && modifiedUserData.firstname !== undefined ? modifiedUserData.firstname : t('guestUserName');
        setUserFullname(name);
        const email = modifiedUserData && 'email' in modifiedUserData && modifiedUserData.email !== undefined ? modifiedUserData.email : 'no@nodkdkw.hu';
        setUserEmail(email);
    });

    useEffect(() => {
        const user = userService.getUser() as UserModel;
        const name = user && 'firstname' in user && user.firstname !== undefined ? user.firstname : t('guestUserName');
        setUserFullname(name);
        const email = user && 'email' in user && user.email !== undefined ? user.email : 'no@nodkdkw.hu';
        setUserEmail(email);
        eventEmitter.on('userDataChanged', updateHeader);
        return () => {
            eventEmitter.off('userDataChanged', updateHeader);
        };
    }, []);

    return (
        <>
            <Navbar bg="dark" data-bs-theme="dark">
                <Container data-bs-theme="dark">
                    <Navbar.Brand href="/dashboard"><Figure className="logo-small"></Figure></Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            {t('welcomeUser')}{userFullname}
                            <small className="header-email">{userEmail}</small>
                        </Navbar.Text>
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <FontAwesomeIcon icon={faBars} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="/places">{t('menuPlaces')}</Dropdown.Item>
                                <Dropdown.Item href="/events">{t('menuEvents')}</Dropdown.Item>
                                <Dropdown.Item href="/tickets">{t('menuTickets')}</Dropdown.Item>
                                <Dropdown.Item href="/challenges">{t('menuChallanges')}</Dropdown.Item>
                                <Dropdown.Item href="/profile">{t('menuMyTickets')}</Dropdown.Item>
                                <Dropdown.Item href="/logout">{t('menuLogout')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default Menu;