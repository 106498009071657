import React from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.scss';

import Layout from './components/Layout';
import Nopage from './components/Nopage';
import Dashboard from './components/dashboard/Dashboard';
import Login from './components/authentication/Login/Login';
import Logout from './components/authentication/Logout/Logout';
import Register from './components/authentication/Registration/Register';
import Reminder from './components/authentication/PasswordReminder/Reminder';
import PasswordChange from './components/authentication/PasswordChange/PasswordChange';
import Locations from './components/locations/Locations';
import Events from './components/events/Events';
import Tickets from './components/tickets/Tickets';
import Challenges from './components/challenges/Challenges';
import Profile from './components/Profile/Profile';
import PaymentCompleted from './components/payment/PaymentCompleted';

import UserModel from './models/user.model';
import Confirmation from './components/authentication/Registration/Confirmation';

function App() {
  const [login, setLogin] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem('userData');
    const userData: UserModel = user ? JSON.parse(user) : {};
    if (userData.id) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />} />
        <Route index element={ login ? <Dashboard /> : <Login />} />
        <Route path="/dashboard" element={login ? <Dashboard /> : <Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={login ? <Logout /> : <Login />} />
        <Route path="/registration" element={<Register />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/reminder" element={<Reminder />} />
        <Route path="/passwordchange" element={login ? <PasswordChange /> : <Login />} />
        <Route path="/locations" element={login ? <Locations /> : <Login />} />
        <Route path="/events" element={login ? <Events /> : <Login />} />
        <Route path="/tickets" element={login ? <Tickets /> : <Login />} />
        <Route path="/challenges" element={login ? <Challenges /> : <Login />} />
        <Route path="/profile" element={login ? <Profile /> : <Login />} />

        <Route path="/transaction-completed/:transactionID" element={<PaymentCompleted />} />

        <Route path="*" element={<Nopage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
