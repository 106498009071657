import { useEffect, useState } from "react";
import { Col, Container, Row, Card, Form, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserModel from '../../models/user.model';
import UserService from "../../services/User.service";
import Menu from "../layouts/Menu";
import FieldEditor from "../common/FieldEditor";
import AccountSecurity from "../common/AccountSecurity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import eventEmitter from "../../services/EventProvider.service";
import { toast, ToastContainer } from "react-toastify";
import UserDetails from "./UserDetails";

const Profile = () => {
    const { t } = useTranslation();
    const userService = new UserService();
    const [userData, setUserData] = useState<UserModel>({});
    const [validated, setValidated] = useState(false);
    const [inAction, setInAction] = useState(false);
    const [minimalData, setMinimalData] = useState(false);

    document.body.className='';

    useEffect(() => {
        const user = userService.getUser() as UserModel;
        setUserData(user);
        setMinimalData(checkForUserMinimalInformations(user));
        eventEmitter.on('userDataChanged', saveUserData);
        return () => {
            eventEmitter.off('userDataChanged', saveUserData);
        };
    }, []);

    const saveUserData = (modifiedUserData: UserModel) => {
        setUserData(modifiedUserData);
        userService.modifyUser(modifiedUserData).then((response: any) => {
            if ('status' in response) {
                if (response.status === 200) {
                    toast.success(t('profileUpdated'));
                }
                else {
                    toast.error(t('profileUpdateError'));
                }
            }
        });
    };

    const showProfile = () => {
        // Display the user's profile data
    };

    const showSetting = () => {
        // Display the user's profile settings
    };

    const showBilling = () => {
        // Display the user's profile billing data
    };

    const checkForUserMinimalInformations = (user: UserModel) => {
        if (user.firstname === null || user.lastname === null || user.phone === null || user.birthdate === null || user.gender === null) {
            return false;
        }
        else {
            return true;
        }
    };

    return (
        <>
        <Menu />
        <Container>
            <ToastContainer />
            <Row className="content align-items-start">
                <Col lg={3} md={6} sm={12} xs={12}>
                    <Card className="profile">
                        <Card.Header>
                            {t('Felhasználói beállítások')}
                        </Card.Header>
                        <Card.Body>
                            <ul className="sub-navigation">
                                <li><a onClick={showProfile} href="javascrip:;" className="active-item">{t('menuProfileData')}</a></li>
                                { minimalData && 
                                <>
                                <li><a onClick={showSetting} href="javascrip:;">{t('menuProfileSettings')}</a> <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon></li>
                                <li><a onClick={showBilling} href="javascrip:;">{t('menuProfileBilling')}</a></li>
                                </>
                                }
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={9} md={6} sm={12} xs={12}>
                    <Form className="page-profile">
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <Card className="profile">
                                    <Card.Body>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <h1>{t('focimBelepesiAdatok')}</h1>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <FieldEditor fieldName="email" defaultValue="vendeg@gmail.com" secureText={false} buttonField="btnModifyEmail" disabled={true}></FieldEditor>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <FieldEditor fieldName="password" defaultValue="0123456789" secureText={true} buttonField="btnModifyPassword" disabled={false}></FieldEditor>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <AccountSecurity></AccountSecurity>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                    <br />
                    <UserDetails />
                    <br />
                </Col>
            </Row>
        </Container>
        </>
    );
};

export default Profile;