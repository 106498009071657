import { useState } from "react";
import { Col, Container, Row, Card, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PasswordModel from '../../../models/password.model';
import UserService from '../../../services/User.service';
import PageHeader from "../../layouts/Header";
import { useNavigate } from "react-router-dom";

const PasswordChange = () => {
    const { t } = useTranslation();
    const userService = new UserService();
    const [passwordData, setPasswordData] = useState<PasswordModel>({});
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();

    const onReminderSend = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        }
        else {
            userService.changePassword(passwordData).then((response: any) => {
            });
        }
        event.preventDefault();
        event.stopPropagation();
    };

    const handleFormChange = (event: any) => {
        const { name, value } = event.target;
        setPasswordData({...passwordData, [name]: value });
    };

    const onBack = () => {
        navigate('/login');
    };

    return (
        <Container>
            <PageHeader />
            <Row className="content">
                <Col lg={6} md={6} sm={12} xs={12}>
                    <h1>{t('focimPassChange')}</h1>
                    <Form noValidate validated={validated} onSubmit={onReminderSend}>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <Card>
                                    <Card.Title className="sub-title">{t('passChangeData')}</Card.Title>
                                    <Card.Body>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="mb-3" as={Col} controlId="password">
                                                    <Form.Label>{t('Password')}</Form.Label>
                                                    <Form.Control required className="nsu-form-control" type="password" name="password" value={passwordData.password} onChange={handleFormChange} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t('validPassword')}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="mb-3" as={Col} controlId="password_repeat">
                                                    <Form.Label>{t('password_repeat')}</Form.Label>
                                                    <Form.Control required className="nsu-form-control" type="password" name="password_repeat" value={passwordData.password_repeat} onChange={handleFormChange} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t('password_repeatPlaceholder')}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="top-margin">
                            <Col lg={6} md={12} sm={12} xs={12}>
                                <Button type="submit" className="btn btn-nsu" variant="danger">{t('changePasswordBtn')}</Button>
                            </Col>
                            <Col lg={6} md={12} sm={12} xs={12}>
                                <Button type="button" className="btn btn-nsu" variant="light" onClick={onBack}>{t('Megsem')}</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default PasswordChange;