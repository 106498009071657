import { Col, Row, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldVirus } from "@fortawesome/free-solid-svg-icons";

const AccountSecurity = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="field-editor">
                <Row className="align-items-center">
                    <Col md={1} sm={12} xs={12}>
                        <FontAwesomeIcon icon={faShieldVirus}></FontAwesomeIcon>
                    </Col>
                    <Col md={8} sm={12} xs={12}>
                        <label>{t('userAccountSecurityLabel')}</label>
                        <span>{t('userAccountSecurityDescription')}</span>
                    </Col>
                    <Col md={3} sm={12} xs={12}>
                        <Form.Check type="switch" id="securityStatus" label="Bekapcsolás" />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default AccountSecurity;