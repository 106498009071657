import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import hu from './translations/hu.json';
import en from './translations/en.json';

const resources = {
    hu: {
        translation: hu.translation
    },
    en: {
        translation: en.translation
    }
}

i18n
.use(initReactI18next)
.init({
    resources, 
    lng: 'hu', 
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
