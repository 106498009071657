import axios from "axios";
import config from "../config.json";

import UserModel from "../models/user.model";
import LoginModel from '../models/login.model';
import PasswordModel from "../models/password.model";

class UserService {

    private endpointPrefix = config.API.URL + '/user';
    private headerConfig = {
        'Content-Type': 'application/json'
    };

    public registerUser(userdata: UserModel) {
        return new Promise<any>((resolve, reject) => {
            axios.post(this.endpointPrefix, userdata, {headers: this.headerConfig})
            .then((response: any) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
        });
    }

    public checkConfirmationCode(userdata: UserModel) {
        return new Promise<any>((resolve, reject) => {
            axios.get(this.endpointPrefix + '/confirm/' + userdata.confirmationCode)
            .then((response: any) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
        });
    }

    public authenticateUser(loginData: LoginModel) {
        return new Promise<any>((resolve, reject) => {
            axios.post(this.endpointPrefix + '/login', {
                email: loginData.email, 
                password: loginData.password
            }, {headers: this.headerConfig}).then((response: any) => {
                resolve(response);
            }).catch((error) => {
                resolve(error);
            });
        });
    }

    public getUser() {
        const userObj = localStorage.getItem('userData');
        if (userObj) {
            return JSON.parse(userObj) as UserModel;
        } else {
            return null;
        }
    }

    public getUserData(user_id: number) {
        return new Promise<any>((resolve, reject) => {
            axios.get(this.endpointPrefix + '/' + user_id).then((response: any) => {
                resolve(response);
            }).catch((error) => {
                resolve(error);
            });
        });
    }

    public modifyUser(userdata: UserModel) {
        return new Promise<any>((resolve, reject) => {
            axios.put(this.endpointPrefix + '/' + userdata.id, userdata, {headers: { 'SMB': userdata.token }})
            .then((response: any) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
        });
    }

    public confirmUser(confirm_code: string) {
        return new Promise<any>((resolve, reject) => {
            axios.get(this.endpointPrefix + '/confirm/?confirmationcode=' + confirm_code).then((response: any) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public forgottenPassword(userData: UserModel) {
        return new Promise<any>((resolve, reject) => {
            axios.post(this.endpointPrefix + '/forgottenpassword', { email: userData.email }, {headers: this.headerConfig})
            .then((response: any) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
        });
    }

    public changePassword(passwordData: PasswordModel) {
        return new Promise<any>((resolve, reject) => {
            axios.post(this.endpointPrefix + '/forgottenpassword', { 
                email: passwordData.email,
                password: passwordData.password,
                remindercode: passwordData.remindercode
            })
            .then((response: any) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public chekcEmailValidity(event: any) {
        const email = event.target.value;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(email)) {
            event.target.setCustomValidity('');
        }
        else {
            event.target.setCustomValidity('invalid');
        }
    }

    public checkPasswordComplexity(event: any) {
        const password = event.target.value;
        let complexity = 0;
        if (password.length >= 10) {
            complexity++;
        }
        if (/[a-z]/.test(password)) {
            complexity++;
        }
        if (/[A-Z]/.test(password)) {
            complexity++;
        }
        if (/\d/.test(password)) {
            complexity++;
        }
        if (/[^a-zA-Z0-9]/.test(password)) {
            complexity++;
        }
        if (complexity >= 3) {
            event.target.setCustomValidity('');
        }
        else {
            event.target.setCustomValidity('invalid');
        }
    }

}

export default UserService;