import { Row, Col } from "react-bootstrap";
import Menu from "../layouts/Menu";

const PaymentCompleted = () => {
    return (
        <>
            <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                    <Menu />
                    <h1>PaymentCompleted Page</h1>
                </Col>
            </Row>
        </>
    );
};

export default PaymentCompleted;