import { Figure, Row, Col } from "react-bootstrap";

const PageHeader = () => {
    return (
        <Row>
            <Col className="header">
                <Figure className="logo"></Figure>
            </Col>
        </Row>
    );
};

export default PageHeader;