import { useEffect, useState } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserModel from '../../models/user.model';
import eventEmitter from '../../services/EventProvider.service';


const FieldEditor = ({ fieldName, defaultValue, secureText, buttonField, disabled }: { fieldName: string, defaultValue: string, secureText: boolean, buttonField: string, disabled: boolean }) => {
    const { t } = useTranslation();
    const [userData, setUserData] = useState<UserModel>({});
    const [displayData, setDisplayData] = useState('');
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        const user = localStorage.getItem('userData');
        const userData: any = user ? JSON.parse(user) : {};
        setUserData(userData);
        let requestedData = '';
        requestedData = fieldName in userData && userData[fieldName] !== undefined ? userData[fieldName] : defaultValue;
        if (secureText) {
            requestedData = requestedData.replace(/./g, '*');
        }
        setDisplayData(requestedData);
    }, []);

    const onChangeToEdit = (event: any) => {
        setEditMode(true);
    };

    const onFieldChange = (event: any) => {
        const value = event.target.value;
        setDisplayData(value);
    };

    const onSaveChangedField = (event: any) => {
        setUserData({...userData, [fieldName]: displayData });
        eventEmitter.emit('userDataChanged', userData);
        setEditMode(false);
    };

    if (!editMode) {
        return (
            <>
                <div className="field-editor">
                    <Row className="align-items-center">
                        <Col md={9} sm={12} xs={12}>
                            <label>{t(fieldName)}</label>
                            <span>{displayData}</span>
                        </Col>
                        <Col md={3} sm={12} xs={12}>
                            { !disabled && 
                                <Button className="btn btn-inline-nsu" onClick={onChangeToEdit}>{t(buttonField)}</Button>
                            }
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
    else {
        return (
            <>
                <div className="field-editor">
                    <Row className="align-items-center">
                        <Col md={9} sm={12} xs={12}>
                            <Form.Control type="text" value={displayData} name={fieldName} onChange={onFieldChange} />
                        </Col>
                        <Col md={3} sm={12} xs={12}>
                            <Button className="btn btn-inline-nsu" onClick={onSaveChangedField}>{t('btnSave')}</Button>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
};

export default FieldEditor;